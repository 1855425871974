<template>
	<div class="transmit_sub_container">
		<div style="overflow-y: scroll;height: 110px;" class="transmittal_slip_filter adj_sur">
			<div class="label_element_cover">
				<label>Enter</label>
				<input class="input_300" type="text" placeholder="Search by Receipt No/Customer Ref/AWB No"
					v-model="post_data.value" @keyup.enter="searchReceiptNo" @keydown="nameKeydown($event)" />
			</div>
			<div class="label_element_cover">
				<label>Filter By Date</label>
				<flat-pickr class="date_for_pos_fixed" v-model="daterange" :config="configDate" placeholder="Date"
					name="birthdate" @on-close="dateChanged" @on-open="cleardatepicker"></flat-pickr>
			</div>
			<!-- <div class="label_element_cover" v-if="'Courier' != bookingType">
				<label v-if="auth().user_type != 'super_admin' && auth().user_type != 'customer_service_user'">In /
					Out</label>
				<select v-model="inoutfilter" @change="filterInOut"
					v-if="auth().user_type != 'super_admin' && auth().user_type != 'customer_service_user'">
					<option value disabled="disabled" selected="selected">Select status</option>
					<option value="all">All</option>
					<option value="Incoming">Incoming</option>
					<option value="Outgoing">Outgoing</option>
				</select>
			</div> -->
			<div class="label_element_cover">
				<label>Filter By Status</label>
				<select v-model="status" @change="filterStatus">
					<option value disabled="disabled" selected="selected">Select status</option>
					<option value="all">All</option>
					<option value="Created">Created</option>
					<option value="Booked">Booked</option>
                    <option value="Allocated">Allocated</option>
					<option value="Delivered">Delivered</option>
					<option value="Dispatched">Dispatched</option>
					<option value="Scan Run">Scan Run</option>
					<option value="Not Home">Not Home</option>
					<option value="PickedUp">PickedUp</option>
					<option value="Received">Received</option>
					<option value="RTS">RTS</option>
					<option value="Transmitted">Transmitted</option>
					<option value="WC">WC</option>
					<option value="Error">Error</option>
					<option value="Label Generated">Label Generated</option>
					<option value="Manifest Generated">Manifest Generated</option>
					<option value="Label Printed">Label Printed</option>
				</select>
			</div>
			<div class="label_element_cover">
				<label>Filter By Address</label>
				<input class="input_300" type="text" placeholder="Search by Pickup Address / Delivery Address"
					v-model="post_data.address" @change="searchAddress" />
			</div>
			<div class="label_element_cover">
				<label>Type of Jobs</label>
				<select v-model="typeOfJob" @change="filterCourierType">
					<option value="">All</option>
					<option :value="data" v-for="(data, i) in filters" :key="i">{{ data }}</option>
					<option v-if="'Courier' == bookingType" value="Postal">Postal</option>
					<option v-if="'Courier' == bookingType" value="Domestic">Domestic</option>
					<option v-if="'Courier' == bookingType" value="International">International</option>
				</select>
			</div>
			<div class="label_element_cover">
				<div class="noselect"  title="Download" ref="download" @click="exportData" style="padding: 10px;background: #06a5ed;margin-top: 22px;margin-left: 20px;cursor: pointer;border-radius: 5px;color: #02004c;">EXPORT</div>
			</div>
		</div>
		<section class="print_hide">
			<div class="two_tabs">
				<a class="tab_buttons active" v-if="page_type == 'Courier'">
					COURIER
					<span class="label">24</span>
				</a>
				<a class="tab_buttons active" v-if="page_type == 'Encode'">

					ENCODE
					<span class="label">24</span>
				</a>
				<a class="tab_buttons active" v-if="page_type == 'Mailroom'">

					MAILROOM
					<span class="label">24</span>
				</a>

				<!-- <a class="tab_buttons">TRANSPORT</a> -->

			</div>
			<div class="jobs_table">
				<table>
					<tr>
						<th>DATE</th>
						<th>SERVICE TYPE</th>
						<th>CARRIER</th>
						<th>BOOKED BY</th>
						<th>ITEM TYPE</th>
						<th>PICKUP ADDRESS</th>
						<th>DELIVERY ADDRESS</th>
						<th>PICKUP/DELIVERY INSTRUCTION</th>
						<th>STATUS</th>
					</tr>
					<tbody v-if="!loading">
						<tr v-for="(data, i) in courier" :key="i">
							<td>
								<p class="text-center">
									<span class="label">{{ data.booking_type }}</span>
								</p>
								<p class="text-center">{{ data.created_at | formatDate }}</p>
							</td>
							<td>
								<span v-if="'Postal' != data.delivery_service_type" class="label">{{ data.delivery_service }}</span>
								<p v-if="'Courier' == bookingType">Cut of time: {{ data.office_close_time }}</p>
							</td>
							<td>
								<p v-if="'Postal' == data.delivery_service_type">
									AUS Post
								</p>
								<p v-if="data.provider_name">{{ data.provider_name.name }}</p>
								<template v-if="'Courier' == bookingType">
									<div v-if="(data.booking_type != 'Batch Upload') && (data.airway_bill == data.booking_id)">
										<p>AWB No:{{ data.booking_prefix }}{{ data.airway_bill | truncate(20, '...') }}
										</p>

									</div>
									<div v-else>
										<p>AWB No:<br><span style="text-transform: uppercase;">{{ data.con_no ? data.con_no : data.airway_bill | truncate(20, '...')}}</span></p>
									</div>
									<div>
										<p>Charged Value: <br> {{ data.cost }}</p>
									</div>
								</template>
							</td>
							<td>
								<p>
									<b>{{ data.booking_prefix }}{{ data.booking_id }}</b>
								</p>

								<p>{{ data.pickup_name }}</p>
								<p>{{ data.pickup_date }} {{ data.pickup_time }}</p>
								<p>{{ data.branch_name }}</p>
								<p>Customer Reference: {{ data.reference_no }}</p>
							</td>
							<td>
								<!-- {{data.type_of_good}} -->
								<div v-if="data.goods !== null">
									<!-- {{data.goods.type}} -->
									{{ data.goods }}
								</div>
								<div v-else>
									{{ data.type_of_good }}
								</div>
							</td>
							<td>
								<p>Collect from: {{ data.package_location }}</p>
								<p>{{ data.pickup_name }}</p>
								<p>
                                    <span v-if="data.pickup_desk">Desk# {{ data.pickup_desk }}, </span>
                                    <span v-if="data.pickup_floor">Floor# {{ data.pickup_floor }}, </span>
                                    {{ data.pickup_address_1 ? data.pickup_address_1 + ', ' : '' }}
									{{ data.pickup_address_2 ? data.pickup_address_2 + ', ' : '' }}
									{{ data.pickup_city ? data.pickup_city + ', ':''}}
                                    {{ data.pickup_state ? data.pickup_state + ', ' : '' }}
                                    {{ data.pickup_postal_code ? data.pickup_postal_code + ', ' : '' }}
                                    {{ data.pickup_country }}
                                </p>
								<p v-if="data.matter_number && matterNumberEnabled">Matter Number: {{data.matter_number.matter_number}}</p>
								<p v-if="(!data.matter_number && data.gl_code) && matterNumberEnabled">GL Code: {{`${data.gl_code.office}-${data.gl_code.entity}-${data.gl_code.department}-${data.gl_code.section}-000`}}</p>
							</td>
							<td>
								<p>Send to:</p>
								<p v-if="'Postal' == data.delivery_service_type">{{ data.recipient_name }}</p>
								<p v-else>{{ data.delivery_name }}</p>
								<p>{{ data.delivery_company }}</p>
								<p>
                                    <span v-if="data.delivery_desk">Desk# {{ data.delivery_desk }}, </span>
                                    <span v-if="data.delivery_floor">Floor# {{ data.delivery_floor }}, </span>
                                    {{ data.delivery_address_1 ? data.delivery_address_1 + ', ' : '' }}
									{{ data.delivery_address_2 ? data.delivery_address_2 + ', ' : '' }}
									{{ data.delivery_city ? data.delivery_city + ', ' : '' }}
									{{ data.delivery_state ? data.delivery_state + ', ' : '' }}
									{{ data.delivery_postal_code ? data.delivery_postal_code + ', ' : '' }}
									{{ data.delivery_country }}
                                </p>
								<template v-if="data.secretary">
									<b>Secretary:</b>
									<p style="padding-bottom: 0px;">{{ data.secretary.name }}</p>
                                    <span v-if="data.secretary.desk && extraAddressFieldEnabled">Desk# {{ data.secretary.desk }}, </span>
                                    <span v-if="data.secretary.floor && extraAddressFieldEnabled">Floor# {{ data.secretary.floor }}, </span>
									{{ data.secretary.address_2 ? data.secretary.address_2 + ', ' : '' }}
									{{ data.secretary.city ? data.secretary.city + ', ' : '' }}
									{{ data.secretary.state ? data.secretary.state + ', ' : '' }}
									{{ data.secretary.postal_code ? data.secretary.postal_code + ', ' : '' }}
									{{ data.secretary.country }}
								</template>
							</td>
							<td>
								<p>Item Description: <span><br />{{ data.goods_description || 'NA' }}</span></p>
								<p>Delivery Instruction: <span><br />{{ data.remarks || 'NA' }}</span></p>
								<p>Pickup Instruction: <span><br />{{ data.pickup_instructions || 'NA' }}</span></p>
								<!-- <p>Delivery Instruction: <span><br/>{{data.pickup_instructions || 'NA'}}</span></p> -->
							</td>
							<td v-if="'Postal' != data.delivery_service_type">
								<div style="display:flex;">
									<span class="label green"
										v-if="auth().user_type == 'user' || auth().user_type == 'hub_user' || auth().user_type == 'hub_admin'">
										{{ data.mail_type }}
									</span>
									<div class="label new-booked" v-if="data.requester && !data.requester.is_admin && 'Booked' == data.status && (auth().user_type == 'hub_user' || auth().user_type == 'hub_admin')">
										!
										<span class="tooltiptext">New Job!</span>
									</div>
								</div>
								<div class="icons_wrapper">
									<div class="view_optons_wrapper" v-if="data.show_view_popup">
										<a class="view_option_button" @click="searchConsignment(data)">View Receipt</a>
										<a class="view_option_button" @click="viewSummary(data)">View Summary</a>
										<!-- <a class="view_option_button" v-if="data.airway_bill && (data.file_name != null)"
											:href="data.label_url" target="_blank" @click="statusChange(data)">Print
											Label</a> -->
										<a class="view_option_button" v-if="data.airway_bill && (data.file_name != null) && data.booking_type != 'Mailroom' && data.commercial_file_name == null" :href="data.label_url" target="_blank" @click="statusChange(data)">Print Label</a>
										<a class="view_option_button" v-if="data.status != 'Delivered' && data.booking_type == 'Mailroom'" @click="statusChange(data)">Reprint Label</a>
										<a class="view_option_button"
											v-if="data.airway_bill && (data.commercial_file_name != null)"
											:href="data.commercial_url" target="_blank">Print Label</a>
										<a class="view_option_button" @click="searchTrack(data)">Track & Trace</a>
										<a class="view_option_button" v-if="data.delivery_service_type && data.delivery_service_type.toLowerCase() == 'international'" @click="getInternationalShipmentTracking(data)">International Shipment Tracking</a>
										<a class="view_option_button" v-if="data.airway_bill && (data.file_name != null) && data.booking_type != 'Mailroom' && data.pickup_address_category === 'external'" @click="emailExternalPickup(data)">Email External Pickup</a>
									</div>

									<a @click="data.show_view_popup = !data.show_view_popup">
										<i class="material-icons blue" title="View">remove_red_eye</i>
									</a>

									<a v-if="data.booking_type == 'Encode' && (auth().user_type == 'user' || auth().user_type == 'hub_user' || auth().user_type == 'hub_admin')">
										<router-link 
											title="Edit" 
											:to="{ name: 'Encoding', params: { id: data.id } }"
											v-if="data.is_transmittal_slip == 0 && !data.transmittal_slip_id"
										>
											<i class="material-icons red" title="Edit">mode_edit</i>
										</router-link>
										<i class="material-icons grey" title="Could not be edited" v-else>mode_edit</i>
									</a>

									<a v-else-if="data.booking_type == 'Mailroom' && (auth().user_type == 'user' || auth().user_type == 'hub_user' || auth().user_type == 'hub_admin')">
										<router-link 
											title="Edit"
											:to="{ name: 'MailRoom', params: { id: data.booking_id } }"
											v-if="!disableEditActionStatuses.includes(data.status) && auth().user_type == 'hub_admin'"
										>
											<i class="material-icons red" title="Edit">mode_edit</i>
										</router-link>
										<i class="material-icons grey" title="Could not be edited" v-else>mode_edit</i>
									</a>

									<a v-else-if="data.booking_type == 'Courier' || data.booking_type == 'Messenger' && (auth().user_type == 'super_admin' || auth().user_type == 'hub_admin' || auth().user_type == 'user')">
										<router-link 
											title="Edit"
											:to="{ name: 'QuoteAndBook', params: { id: data.id, type: 'edit' } }"
											v-if="!disableEditActionStatuses.includes(data.status) && auth().user_type == 'hub_admin'"
										>
											<i class="material-icons red" title="Edit">mode_edit</i>
										</router-link>
										<i class="material-icons grey" title="Could not be edited" v-else>mode_edit</i>
									</a>

									<a v-else>
										<router-link 
											title="Edit"
											:to="{ name: 'QuickCourierBooking', params: { id: data.id } }"
											v-if="!disableEditActionStatuses.includes(data.status)"
										>
											<i class="material-icons red" title="Edit">mode_edit</i>
										</router-link>
										<i class="material-icons grey" title="Could not be edited" v-else>mode_edit</i>
									</a>
									<a>
										<router-link 
											title="Update"
											:to="{ name: 'ServiceUpdate', params: { id: data.id, type: 'update', pageType: page_type } }"
											v-if="auth().user_type == 'hub_admin' || auth().user_type == 'super_admin'"
										>
											<i class="material-icons red" title="Update">update</i>
										</router-link>
										<i class="material-icons grey" title="Could not be updated" v-else>update</i>
									</a>

									<a v-if="auth().user_type == 'super_admin' || auth().user_type == 'hub_admin'">
										<i @click="deleteBooking(data.id)"
											v-if="!disableDeleteActionStatuses.includes(data.status)"
											class="material-icons red" title="Delete">delete</i>
										<i class="material-icons grey" title="Could not be deleted" v-else>delete</i>
									</a>
									<a v-else>
										<i @click="deleteBooking(data.id)"
											v-if="!disableDeleteActionStatuses.includes(data.status)"
											class="material-icons red" title="Delete">delete</i>
										<i class="material-icons grey" title="Could not be deleted" v-else>delete</i>
									</a>

									<a v-if="data.courier_comments.length > 0" @click="showpopCommentDiary = true">
										<i class="material-icons green" @click="addcomment(data)"
											title="Type your Comment here">comment</i>
									</a>
									<a v-else @click="showpopCommentDiary = true">
										<i class="material-icons orange" @click="addcomment(data)"
											title="Type your Comment here">comment</i>
									</a>
								</div>
								<a class="bt bt_black">{{ data.status }}</a>
								<a class="bt bt_gold"
									v-if="auth().user_type == 'hub_admin' || auth().user_type == 'super_admin' && data.status != 'Booking uploaded'"
									@click="updatestatus(data)">Update Status</a>
							</td>
							<td v-else>
								<span class="label green"
									v-if="auth().user_type == 'user' || auth().user_type == 'hub_user' || auth().user_type == 'hub_admin'">
									{{ data.mail_type }}
								</span>
								<a href="javascript:void(0)">
									<i @click="deleteBooking(data.id)" class="material-icons grey" title="Delete">delete</i>
								</a>
							</td>
						</tr>
					</tbody>
				</table>
				<p v-if="!loading && courier.length == 0">No data found</p>
			</div>
			<loader v-if="loading" />
			<paginate v-if="pages > 0" v-show="!loading" v-model="current_page" :page-count="pages"
				active-class="paginate-active" :click-handler="fetchCouriers" prev-link-class="paginate-previous"
				next-link-class="paginate-next" :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'"
				:no-li-surround="true">
			</paginate>
		</section>
		<div class="black-overlay" v-if="popupdatestatus_booking_id">
			<div class="box-modal smallPops">
				<div class="body">
					<popUpdateStatus @closeModal="popupdatestatus_booking_id = '', fetchCouriers(current_page);"
						:booking_prefix="popupdatestatus_booking_prefix" :airway_bill="popupdatestatus_airway_bill"
						v-if="popupdatestatus_booking_id" />
				</div>
			</div>
		</div>

		<div class="black-overlay" v-if="popcommentdiary_id">
			<div class="box-modal smallPops">
				<div class="body">
					<popCommentDiary @closeModal="popcommentdiary_id = ''" :id="popcommentdiary_id"
						v-if="popcommentdiary_id" />
				</div>
			</div>
		</div>

		<div class="black-overlay" v-show="viewconsignmentpopup_booking_id">
			<div class="box-modal printPops">
				<div class="body">
					<ViewConsignment @closeModal="closeConsignment" :booking_id="viewconsignmentpopup_booking_id"
						:booking_prefix="viewconsignmentpopup_booking_prefix" v-if="viewconsignmentpopup_booking_id" />
				</div>
			</div>
		</div>

		<div class="black-overlay" v-if="tracktracepopup_booking_id">
			<div class="box-modal trackPops">
				<div class="body">
					<!-- Comment -->
					<TrackTracePopup @closeModal="tracktracepopup_booking_id = ''" :booking_id="tracktracepopup_booking_id"
						:booking_prefix="tracktracepopup_booking_prefix" v-if="tracktracepopup_booking_id" />
					<!-- Comment -->
				</div>
			</div>
		</div>
		<div class="black-overlay" v-if="fetched_booking">
			<div class="box-modal receiptPops">
				<div class="body">
					<MailroomReceipt @closeModal="resetData" :data="fetched_booking" v-if="fetched_booking"/>
				</div>
			</div>
		</div>
        <div class="black-overlay" v-if="internationalTrackingPopupBookingId">
			<div class="box-modal trackPops">
				<div class="body">
					<!-- Comment -->
					<InternationalShipmentTrackingPopup 
                        @closeModal="internationalTrackingPopupBookingId = ''" 
                        :airwayBill="internationalTrackingPopupAirwayBill"
						:bookingId="internationalTrackingPopupBookingId" 
                        v-if="internationalTrackingPopupAirwayBill" 
                    />
					<!-- Comment -->
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import popUpdateStatus from "./popups/popUpdateStatus.vue";
import popCommentDiary from "./popups/popCommentDiary.vue";
import ViewConsignment from "./ViewConsignment.vue";
import TrackTracePopup from "./TrackTracePopup.vue";
import moment from "moment";
import MailroomReceipt from "./MailroomReceipt.vue";
import { ConfigurationEnum } from "@/enums";
import { mapGetters, mapActions } from 'vuex'
import InternationalShipmentTrackingPopup from './InternationalShipmentTrackingPopup.vue';

export default {
	name: "ViewPending",
	components: {
		flatPickr,
		popUpdateStatus,
		popCommentDiary,
		ViewConsignment,
		TrackTracePopup,
		MailroomReceipt,
        InternationalShipmentTrackingPopup,
	},
    computed: {
        ...mapGetters({
            configurations: 'configuration/configurations',
        }),
    },
	data() {
		return {
			receiptNo: "",
			daterange: moment(new Date()).format('YYYY-MM-DD'),
			status: "",
			inoutfilter: "",
			api_url:'',
			addressFilter:"",
			bookingType:this.$route.meta.bookingType,
			typeOfJob: "",
			enableTime: true,
			configDate: {
				mode: "range",
				format: "d-m-Y",
				altFormat: "d-m-Y",
				altInput: true
			},
			viewBoxShow: false,
			showpopUpdateStatus: false,
			showpopCommentDiary: false,
			showViewConsignment: false,
			courier: [],
			pages: 0,
			page_type: "",
			filters: [],
			current_page: 0,
			post_data: {
				from: '',
				to: '',
				value: "",
				statusfilter: "",
				jobType: "",
				inout: "",
				view_pending: 1,
				fromDahbaord: false,
				address: "",
			},
			tracktracepopup_booking_id: "",
			tracktracepopup_booking_prefix: "",
			viewconsignmentpopup_booking_id: "",
			viewconsignmentpopup_booking_prefix: "",
			popupdatestatus_booking_id: "",
			popupdatestatus_booking_prefix: "",
			popcommentdiary_id: "",
			popupdatestatus_airway_bill: "",
			loading: true,
			fetched_booking: '',
            matterNumberEnabled: 1,
            extraAddressFieldEnabled: 1,
            viewAllPendingJobsEnabled: 1,
            disableEditActionStatuses: ['Slip Generated', 'Allocated', 'Label Generated', 'Label Printed', 'Dispatched', 'Scan Run', 'Delivered', 'Delivery Started'],
            disableDeleteActionStatuses: ['Slip Generated', 'Allocated', 'Label Printed', 'Dispatched', 'Scan Run', 'Delivered', 'Delivery Started'],
            internationalTrackingPopupAirwayBill: '',
            internationalTrackingPopupBookingId: '',
		};
	},
	filters: {
        truncate: function (text, length, suffix) {
            if (text && text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
		formatDate(value) {
			if (!value) {
				return;
			}

			return moment(String(value)).format('DD-MM-YYYY')
		},
	},
    created() {
		this.api_url = process.env.VUE_APP_API_URL;
    },
	async mounted() {
        this.matterNumberEnabled = this.configurations[ConfigurationEnum.MATTER_NUMBER];
        this.extraAddressFieldEnabled = this.configurations[ConfigurationEnum.EXTRA_ADDRESS_FIELD];
        this.viewAllPendingJobsEnabled = this.configurations[ConfigurationEnum.VIEW_ALL_PENDING_JOBS];

        if (this.viewAllPendingJobsEnabled) {
            if ('Courier' == this.$route.meta.bookingType) {
				this.post_data.inout = "Outgoing"
                this.filters = [
                    'Courier',
                    'Messenger',
                    'Batch Upload',
                ];
			} else if ('Mailroom' == this.$route.meta.bookingType) {
				this.post_data.inout = "Incoming"
                this.filters = [
                    'Mailroom',
                ];
			}
            
        }
        await this.setConfigurations();
        await this.initializeData();
	},
	methods: {
		exportData(page = 1) {
			let method = "POST";
			let input;
			let form = document.createElement("form");
			form.setAttribute("method", method);
			form.setAttribute(
				"action",
				this.api_url +
				"/api/courier/" + page + "/listingAndFilter/download?token=" +
				localStorage.token
			);
			Object.keys(this.post_data).forEach(item => {
				input = document.createElement("input");
				input.type = "text";
				input.name = item;
				input.value = this.post_data[item];
				form.appendChild(input);
			});
			this.$refs.download.appendChild(form);
			form.submit();
			this.$refs.download.removeChild(form);
		},
        ...mapActions({
          setConfigurations: 'configuration/setConfigurations',
        }),
		emailExternalPickup (data) {
			this.loading = true;
			this.axios.post("/api/courier/email-external-pickup" , data)
			.then(() => {
				this.loading = false;
				this.toast.success("Email sent.");
			})
			.catch(() => {
				this.loading = false;
			});
		},
		async initializeData() {
			this.$emit("breadCrumbData", ["View Jobs"]);
			if (localStorage.viewPending) {
				let data = JSON.parse(localStorage.viewPending);
				if (data.jobType) {
					this.typeOfJob = data.jobType;
					this.post_data.jobType = data.jobType;
					this.post_data.fromDahbaord = data.fromDahbaord;
				}
				localStorage.removeItem("viewPending");
			}
			await this.fetchCouriers();
		},
		async nameKeydown(event) {
			return true
		},
		async dateChanged(daterange) {
			this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
			this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
			await this.fetchCouriers();
		},
		cleardatepicker() {
			this.daterange = "";
		},
		async searchReceiptNo() {
			await this.fetchCouriers();
		},
		async searchAddress() {
			//this.post_data.address = this.addressFilter;
			await this.fetchCouriers();
		},
		async filterInOut() {
			this.post_data.inout = this.inoutfilter;
			await this.fetchCouriers();
		},
		async filterStatus() {
			this.post_data.statusfilter = this.status;
			await this.fetchCouriers();
		},
		async filterCourierType() {
			this.post_data.jobType = this.typeOfJob;
			this.post_data.fromDahbaord = false;
			await this.fetchCouriers();
		},
		async fetchCouriers(page = 1) {
			this.loading = true;
			this.current_page = page;
			this.page_type = this.viewAllPendingJobsEnabled ? '' : this.$route.meta.bookingType;
			this.post_data.page_type = this.page_type;

			if ('Courier' == this.$route.meta.bookingType) {
				this.post_data.inout = "Outgoing"
			}

			try {
                let response = await this.axios.post("/api/courier/" + page + "/listingAndFilter", this.post_data);
                // console.log(response);
                this.courier = response.data.couriers.list.map(element => {
                    element.show_view_popup = false;
                    element.label_url = element.airway_bill && element.file_name ? process.env.VUE_APP_API_URL + "/labels/" + element.file_name : "";
                    element.commercial_url = element.airway_bill && element.commercial_file_name ? process.env.VUE_APP_API_URL + "/labels/" + element.commercial_file_name : "";
                    return element;
                });
                this.pages = response.data.couriers.pages;

                if (!this.viewAllPendingJobsEnabled) {
                    this.filters = response.data.couriers.filters;
                }
                this.loading = false;
            }
            catch (error) {
                this.loading = false;
                console.log(error);
            }
		},
		closeConsignment() {
			this.viewconsignmentpopup_booking_id = '';
			this.courier = this.courier.map(element => {
				element.show_view_popup = false;
				return element;
			});
		},
		searchConsignment(booking) {
			this.viewconsignmentpopup_booking_prefix = booking.booking_prefix;
			this.viewconsignmentpopup_booking_id = booking.booking_id;
		},
		viewSummary(data) {
			localStorage.view_summary = JSON.stringify({
				booking_id: data.booking_prefix + data.booking_id
			});
			this.$router.push("/pages/ViewProcessed");
		},
		searchTrack(booking) {
			this.tracktracepopup_booking_prefix = booking.booking_prefix;
			this.tracktracepopup_booking_id = booking.booking_id;
		},
		updatestatus(booking) {
			this.popupdatestatus_booking_prefix = booking.booking_prefix;
			this.popupdatestatus_booking_id = booking.booking_id;
			this.popupdatestatus_airway_bill = booking.airway_bill;
		},
		addcomment(id) {
			this.popcommentdiary_id = id.id;
		},
		deleteBooking(id) {
			if (confirm("Are you sure you want to delete this booking?")) {
				this.axios.delete("api/courier/" + id)
					.then(async () => {
						await this.fetchCouriers();
					})
					.catch(error => {
						console.log(error);
					})
			}
		},
		//change label generated status  to label printed on button click
		statusChange(data) {
			if (data.booking_type == 'Mailroom') {
				this.fetched_booking = data;
			}
			else {
				if (data.is_label_printed == 0) {
					this.axios.post("/api/draft/carrierlabel/" + data.booking_id)
						.then(async () => {
							await this.fetchCouriers();
						})
						.catch(error => {
							console.log(error);
						});
				}
			}
		},
		resetData(){
			this.fetched_booking = '';
		},
        getInternationalShipmentTracking(booking) {
			this.internationalTrackingPopupAirwayBill = booking.airway_bill;
            this.internationalTrackingPopupBookingId = booking.id;
		},
	}
};
</script>
<style lang="scss">
$theme-color: #06A5ED;

.jobs_table {
	border-top: 5px solid $theme-color;

	table {
		width: 100%;
		border-collapse: collapse;
		border-left: 1px solid #ccc;
		font-size: 14px;
		margin-bottom: 15px;

		tr {
			th {
				background: #004a7c;
				padding: 20px 10px;
				color: #ccc;
				font-weight: 700;
				border: 1px solid #222;
				border-left: 0;
				border-top: 0;
				text-align: left;
			}

			td {
				background: #eee;
				padding: 20px 10px;
				color: #333;
				font-weight: 400;
				border: 1px solid #ccc;
				border-left: 0;
				border-top: 0;
				text-align: left;
				vertical-align: top;

				p {
					padding: 0 0 10px 0;

					b {
						font-weight: 700;
					}
				}

				p.text-center {
					text-align: center;
				}

				span.label {
					display: inline-block;
					font-weight: 700;
					background: #000;
					color: #fff;
					border-radius: 4px;
					padding: 5px;
					text-align: center;
					margin-bottom: 5px;

					&.green {
						background: #46aa42;
					}
				}

				
				div.label {
					display: inline-block;
					font-weight: 700;
					background: #000;
					color: #fff;
					border-radius: 4px;
					padding: 5px;
					text-align: center;
					margin-bottom: 5px;

					&.new-booked {
						margin-left:10px;
						background: orange;
						cursor:pointer;
						
						position: relative;
						display: inline-block;
						border-bottom: 1px dotted black;
					}
					.tooltiptext {
						visibility: hidden;
						width: 120px;
						background-color: black;
						color: white;
						text-align: center;
						padding: 5px 0;
						border-radius: 6px;
						position: absolute;
						z-index: 1;
						
						width: 120px;
						bottom: 125%;
						left: 50%;
						margin-left: -60px;
					}

					.tooltiptext::after {
						content: " ";
						position: absolute;
						top: 100%;
						left: 50%;
						margin-left: -5px;
						border-width: 5px;
						border-style: solid;
						border-color: black transparent transparent transparent;
					}
						
				}
				.new-booked:hover {
					.tooltiptext {
						visibility: visible;
					}
				} 
				.icons_wrapper {
					padding: 10px 0;
					display: flex;
					justify-content: space-between;
					position: relative;

					a {
						display: inline-block;
						width: 24px;
						height: 24px;

						.material-icons {
							font-size: 18px;
							position: relative;
							top: 0;
							margin: 0;
							font-weight: normal;

							&.blue {
								color: rgb(45, 91, 241);
							}

							&.red {
								color: rgb(241, 74, 45);
							}

							&.green {
								color: #46aa42;
							}

							&.orange {
								color: orange;
							}

							&.grey {
								color: grey;
							}

						}
					}
				}

				.bt {
					width: 120px;
					height: 40px;
					text-align: center;
					line-height: 40px;
					border-radius: 4px;
					display: block;
					margin: 5px 0;
				}

				.bt_black {
					background: #063657;
					color: #fff;
				}

				.bt_gold {
					background: $theme-color;
					color: #000;
				}
			}
		}
	}
}

.two_tabs {
	padding: 40px 10px 0 10px;
	display: flex;
	justify-content: space-around;

	.tab_buttons {
		display: block;
		width: 40%;
		height: 50px;
		text-align: center;
		line-height: 50px;
		background: #ececec;
		color: #333;
		border: 1px solid #ddd;

		span.label {
			display: none;
			background: $theme-color;
			color: #000;
			padding: 5px 10px;
			border-radius: 4px;
		}
	}

	.tab_buttons.active {
		background: #004a7c;
		color: #fff;
	}
}

.view_optons_wrapper {
	width: 240px;
	height: auto;
	background: #fff;
	border: 1px solid #ccc;
	box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
	position: absolute;
	left: -250px;
	top: 10px;
	padding: 10px;

	a.view_option_button {
		width: 100% !important;
		height: 40px !important;
		background: $theme-color;
		color: #000;
		display: block;
		margin-bottom: 10px;
		text-align: center;
		line-height: 40px;
	}

	a.view_option_button:last-child {
		background: #aaa;
		color: #000;

		margin-bottom: 0;
	}
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
				user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
	}

</style>
<style scoped>.jobs_table table tr td:nth-child(6) p:nth-child(2) {
	color: red;
}

.jobs_table table tr td:nth-child(7) p:nth-child(2) {
	color: red;
}</style>